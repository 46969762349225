import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PjeTabComponent } from './pje-tabs/pje-tab/pje-tab.component';
import { PjeTabsComponent } from './pje-tabs/pje-tabs.component';
import { LinkAutosDigitaisComponent } from './link-autos-digitais/link-autos-digitais.component';
import { SpinnerComponent } from 'app/core/spinner/spinner.component';
import { PjeDatatableComponent } from './pje-datatable/pje-datatable.component';
import { PjeCardParteComponent } from './pje-card-parte/pje-card-parte.component';
import { PjeLembretesComponent } from './pje-lembretes/pje-lembretes.component';
import { MatProgressSpinnerModule } from '@angular/material';
import { TableModule } from 'primeng/table';
import { DataListModule } from 'primeng/datalist';
import { SharedModule } from 'primeng/shared';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { AccordionModule } from 'primeng/accordion';
import { PaginatorModule } from 'primeng/paginator';

@NgModule({
	imports: [
		CommonModule,
		DataListModule,
		SharedModule,
		OverlayPanelModule,
		CalendarModule,
		TooltipModule,
		AccordionModule,
		PaginatorModule,
		FormsModule,
		MatProgressSpinnerModule,
		TableModule
	],
	declarations: [
		PjeTabComponent, 
		PjeTabsComponent,
		LinkAutosDigitaisComponent,
		SpinnerComponent,
        PjeDatatableComponent,
        PjeCardParteComponent,
        PjeLembretesComponent
	],
	exports: [
		PjeTabComponent, 
		PjeTabsComponent,
		LinkAutosDigitaisComponent,
		SpinnerComponent,
        PjeDatatableComponent,
        PjeCardParteComponent,
        PjeLembretesComponent
	],

})
export class PjeComponentsModule { }
