import { KeycloakConfig } from 'keycloak-angular';

const keycloakConfig: KeycloakConfig = {
	url: 'https://sso.stg.cloud.pje.jus.br/auth',
	realm: 'pje',
	clientId: 'pje-tjap-frontend'
};


export const environment = {
	production: false,
	urlGateway: "http://localhost:8180",
	contextPathPjeLegacy: "pje",
	keycloak: keycloakConfig
};