import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleNotificationsModule } from 'angular2-notifications';

import { PJeRoutingModule } from './app.routes';
import { CoreModule } from './core/core.module';

import { AppComponent } from './app.component';
import { ForbiddenPageComponent } from './forbidden-page/forbidden-page.component';

import { PjeHttpClientInterceptor } from "./shared/interceptors/pje-http-client.interceptor";

import { AppConfig } from './app.config';

import { registerLocaleData } from "@angular/common";
import localePtBr from "@angular/common/locales/pt";
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { PjeSharedModule } from './shared/pje-shared.module';

import { environment } from 'environments/environment';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { Observable, fromEvent } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { CookieService } from "./core/services/cookies.service";
import { PjeComponentsModule } from "./shared/components/pje-components.module";
import { LoaderService } from './shared/components/pje-loader/loader.service';
import { PjeLoaderInterceptor } from './shared/interceptors/pje-loader.interceptor';
import { NugepModule } from './tjap/nugep/nugep.module';


const messages : Observable<any> = fromEvent(window, "message");

export function initConfig(config: AppConfig){
    return () => config.load()
}

export function keycloakInitializer(keycloak: KeycloakService): () => Promise<any> {

	return () => new Promise((resolve, reject) => {
		messages.pipe(timeout(3000)).subscribe(async event => {
			if (event.data.CONSTANTES) {
				let appConfig : AppConfig = new AppConfig(null);
				appConfig.initPayloadConstantes(event.data.CONSTANTES);
				if(appConfig.ssoEnabled){
					try{
						await keycloak.init({
							config: environment.keycloak,
							initOptions: {
								onLoad: 'check-sso',
								silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
								checkLoginIframe: false,
							},
							bearerExcludedUrls: ['analytics'],
						});
					} catch(error){
						console.log(error);
					}
				}				
				resolve();
			}
		}, err => {resolve()});
	});

  }

registerLocaleData(localePtBr);

@NgModule({
  
	declarations: [
		AppComponent,
		ForbiddenPageComponent ],
	imports: [
    	BrowserModule,
    	HttpClientModule,
    	PJeRoutingModule,
    	BrowserAnimationsModule,
    	SimpleNotificationsModule.forRoot(),
    	CoreModule,
    	PjeSharedModule,
    	KeycloakAngularModule,
    	PjeComponentsModule,
		NugepModule
	],
	providers: [
		AppConfig,
		{ provide: APP_INITIALIZER, useFactory: initConfig, deps: [AppConfig], multi: true },
		{ provide: APP_INITIALIZER, useFactory: keycloakInitializer, multi: true, deps: [KeycloakService]},
		{ provide: HTTP_INTERCEPTORS, useClass: PjeHttpClientInterceptor, multi: true},
		{ provide: HTTP_INTERCEPTORS, useClass: PjeLoaderInterceptor, multi: true, deps: [LoaderService]},
		CookieService, 
	],
	bootstrap: [AppComponent],
	exports: []
})
export class AppModule { 
	static forRoot(): ModuleWithProviders{
		return {
			ngModule: AppModule,
			providers: [AppConfig]
		};
	}
}
